/*! {!project_name!} v1.5.0 */
/**
 * JS for: {!project_name!} v1.5.0
 *
 * Author: {!dev_alias!} @ {!dev_agency!}
 *
 * TOC:
 *
 * Modules
 * jQuery document ready
 * 
 * @todo Add each module/section needed
 * 
 */
const slick = require('slick-carousel');

const lightcase = require('lightcase');
/**
 * Anchor links
 *
 * Remove the base href on anchor links
 * 
 */
function anchorLinks()
{
  if ($('a[href^="#"]').length) {
    $('a[href^="#"]').each(function()
    {
      this.href = location.href.replace(location.hash, '') + 
                  '#' + this.href.substr(this.href.indexOf('#') + 1);
    });
  }
}
/* Form validations */
/**
 * Email validation
 *
 * @param  string email email input field value
 *
 * @return boolean
 */
function email_validation(email) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){

    return true;
  }

  return false;
}
/**
 * Form validations
 *
 * @param  string form Form ID
 *
 * @return boolean
 */
function form_validations(form)
{
  var result = false;
  var form_id = '#' + form;
  switch(form) {
    case 'form-newsletter-subscription':
      if (email_validation($(form_id + ' .input-email').val()) && 
          '' == $(form_id + ' .input-verification').val()) {
        result = true;
      }
      break;
    case 'form-appointment':
    console.log('uno');
    if ('' != $(form_id + ' .input-name').val() && 
          email_validation($(form_id + ' .input-email').val()) && 
          '' != $(form_id + ' .input-telephone').val() &&
          '' != $(form_id + ' .input-comments').val() &&
          '' == $(form_id + ' .input-verification').val()) {
        result = true;
      }
      break;
    case 'form-contact':
    console.log('dos');
      if ('' != $(form_id + ' .input-name').val() && 
          email_validation($(form_id + ' .input-email').val()) && 
          '' != $(form_id + ' .input-telephone').val() &&
          '' != $(form_id + ' .input-comments').val() &&
          '' == $(form_id + ' .input-verification').val()) {
        result = true;
      }
      break;
  }
  return result;
}
/**
 * Form disable
 *
 * Toggle form submit, button label and button loader animation
 *
 * @param  string form Form ID
 * @param  boolean disable
 */
function form_disable(form, disable)
{
  if (disable) {
    $('#' + form + ' .button-submit').prop('disabled', true);
    $('#' + form + ' .label').addClass('hidden');
    $('#' + form + ' .loader').removeClass('hidden');
  } else {
    $('#' + form + ' .button-submit').prop('disabled', false);
    $('#' + form + ' .label').removeClass('hidden');
    $('#' + form + ' .loader').addClass('hidden');
  }
}
/**
 * recaptcha callbacks
 *
 */
function recaptcha_appointment_callback()
{
  ajax_submit('form-appointment');
}
window.recaptcha_appointment_callback = recaptcha_appointment_callback;
function recaptcha_contact_callback()
{
  ajax_submit('form-contact');
}
window.recaptcha_contact_callback = recaptcha_contact_callback;
/**
 * Ajax submit
 *
 * @param  string form Form ID
 *
 */
function ajax_submit(form)
{
  var notification_close_label = '<label for="check-notification-toggle" id="label-notification-toggle" class="float-r">X</label>';
  form_disable(form, true);
  console.log(form);
  /* Js validations */
  if (form_validations(form)) {
    $('.check-notification').prop('checked', true);
    var data = $('#' + form).serializeArray();
    data.push({name: 'is_ajax', value: true});
    /* Ajax */
    $.ajax({
      type: 'POST',
      url: window.location.href,
      data: data,
      dataType: 'json',
      success: function(data)
      {console.log(data.status);
        if (data.status) {
          console.log(data);
          $('.notification')
            .removeClass('information warning success error')
            .addClass(data.notification.type)
            .html('')
            .html(data.notification.message + notification_close_label);
          $('.check-notification').prop('checked', false);
          $('#' + form).trigger('reset');
        } else {
          console.log(data);
          $('.notification')
            .removeClass('information warning success error')
            .addClass(data.notification.type)
            .html('')
            .html(data.notification.message + notification_close_label);
          $('.check-notification').prop('checked', false);
        }
        form_disable(form, false);
      },
      error: function(data)
      {
        console.log(data);
        $('.notification')
          .addClass('error')
          .html('')
          .html('Ocurrio un error al enviar la información, por favor intentalo nuevamente (1).' + notification_close_label);
        $('.check-notification').prop('checked', false);
        form_disable(form, false);
      }
    });

  } else {
    $('.notification')
      .addClass('warning')
      .html('')
      .html('Verifica que hayas llenado todos los campos correctamente.' + notification_close_label);
    $('.check-notification').prop('checked', false);
    form_disable(form, false);
  }

  if ($('.notification').is(':visible')) {
    $('.notification').fadeTo('fast', 0.25).fadeTo('slow', 1.0);
  }
}

function swap_header_logo()
{
  if ($(window).width() <= 1024) {
    $('.header-logo').attr('src', 'img/header-logo-sitsacv.png');
  }
  if ($(window).width() > 1024) {
    $('.header-logo').attr('src', 'img/header-logo-sitsacv.png');
  }
}

// The clients slider
function clients_slider_init()
{
  if ($('.clients-slider').length) {
    $('.clients-slider').slick({
      prevArrow: '<div class="slick-prev"><</div>',
      nextArrow: '<div class="slick-next">></div>',
      infinite: true,
      slidesToShow: 8,
      slidesToScroll: 4, 
      autoplay: true,
  autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 2
          }
        },
      ],
    });
  }

}
/**
 * jQuery document ready
 */
$(document).ready(function()
{
                    
  $( '#festo-home-content').mouseover( () => { 
  $( "#festo-home" ).css("display", "none")
  $( "#festo-home2" ).css("display", "block");
})
 $( '#festo-home-content' ).mouseout( () => {
  $( "#festo-home" ).css("display", "block")
  $( "#festo-home2" ).css("display", "none");
  });


  $( '#gates-home-content').mouseover( () => { 
  $( "#gates-home" ).css("display", "none")
  $( "#gates-home2" ).css("display", "block");
})

  $( '#martin-home-content').mouseover( () => { 
  $( "#martin-home" ).css("display", "none")
  $( "#martin-home2" ).css("display", "block");
})

  $( '#martin-home-content' ).mouseout( () => {
  $( "#martin-home" ).css("display", "block")
  $( "#martin-home2" ).css("display", "none");
  });
 $( '#gates-home-content' ).mouseout( () => {
  $( "#gates-home" ).css("display", "block")
  $( "#gates-home2" ).css("display", "none");
  });

  $( '#brouning-home-content').mouseover( () => { 
  $( "#brouning-home" ).css("display", "none")
  $( "#brouning-home2" ).css("display", "block");
})
 $( '#brouning-home-content' ).mouseout( () => {
  $( "#brouning-home" ).css("display", "block")
  $( "#brouning-home2" ).css("display", "none");
  });

  $( '#sew-home-content').mouseover( () => { 
  $( "#sew-home" ).css("display", "none")
  $( "#sew-home2" ).css("display", "block");
})
 $( '#sew-home-content' ).mouseout( () => {
  $( "#sew-home" ).css("display", "block")
  $( "#sew-home2" ).css("display", "none");
  });

  $( '#unieal-home-content').mouseover( () => { 
  $( "#unieal-home" ).css("display", "none")
  $( "#unieal-home2" ).css("display", "block");
})
 $( '#unieal-home-content' ).mouseout( () => {
  $( "#unieal-home" ).css("display", "block")
  $( "#unieal-home2" ).css("display", "none");
  });
/* The resize window monitor */
  var window_width = window.innerWidth || $(window).width();
  var window_height = window.innerHeight || $(window).height();
  $(window).resize(function() 
  {
    window_width = window.innerWidth || $(window).width();
    window_height = window.innerHeight || $(window).height();
  });

  $('.toggle-sub-menu').on('click', function(e)
    {
       if ($(window).width() <= 767) {
        $("#label-submenu-toggle").css("display", "block");
        
      $('.menu').addClass('menu-a-la-izquierda');
     
      ancho_original=$(".menu").width();
      var porcentaje = "1.64";

      ancho=ancho_original * porcentaje;
      
      ancho=(parseInt(ancho) - parseInt(ancho_original));
      ancho_final=(parseInt(ancho_original) - parseInt(ancho));
      ancho_final=(parseInt(ancho_original) - parseInt(ancho_final) + parseInt(10));

      

       $('.sub-menu').addClass("sub-menu-abierto");
       $('#label-submenu-toggle').addClass("cerrar-movil-sub-menu");
        $('.sub-menu-abierto > li').addClass("dropdown-derecha");

    /* $('.sub-menu').css("left", "108%");
     $('.sub-menu').css("margin-left", "0px"); 
     $('.sub-menu').css("top", "-434%");*/
     $('.sub-menu').css("width", ancho_final);
     /*$('.sub-menu').css("background-color", "#630929"); */
     }

    });


$('#label-menu-toggle').on('click', function(e)
    {
       if ($(window).width() <= 767) {
        
      $('.menu').removeClass('menu-a-la-izquierda');
       $('.sub-menu-abierto > li').removeClass("dropdown-derecha");
       $('#label-submenu-toggle').removeClass("cerrar-movil-sub-menu");
     }

    });

  $('#label-submenu-toggle').on('click', function(e)
    {
       if ($(window).width() <= 767) {
         
        $('.sub-menu-abierto > li').removeClass("dropdown-derecha");
        $('.sub-menu').removeClass("sub-menu-abierto");
        $('#check-submenu-toggle').prop('checked', false);
        
        //$("#label-submenu-toggle").css("display", "none");
        
      $('.menu').removeClass('menu-a-la-izquierda');
      
$('#label-submenu-toggle').removeClass("cerrar-movil-sub-menu");
       //$('.dropdown > li').css("margin-right", "auto");
     }

    });

  /* The anchor links fix */
  //anchorLinks();
  /* The treatments toggle content links */
  if ($('a.toggle-content').length) {
    $('a.toggle-content').on('click', function(e)
    {
      let content_id = this.href.substr(this.href.indexOf('#') + 1);
      console.log(content_id);
      $('ul.treatments-content > li').addClass('hidden');
      $('#' + content_id + '-content').removeClass('hidden');
      e.preventDefault();
    })
  }


clients_slider_init();
	/* The slick slider */
	/* * Home */
	if ($('.slick-slider.main').length) {
		$('.slick-slider.main').slick({
      lazyLoad: 'ondemand',
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      dots: true,
      arrows: true,
			prevArrow: '<div class="slick-prev"><</div>',
  	  nextArrow: '<div class="slick-next">></div>',
      infinite: false,
      adaptiveHeight: false,
      /*responsive: [
      {
        breakpoint: 980,
        settings: {
          dots: false,
          arrows: true
        }
      }
    ]*/
		});
	}
  /* Projects gallery */

  if ($('.slick-slider.gallery').length) {
    $('.slick-slider.thumbs').slick({ 
      arrows: true, 
      slidesToShow: 6,
      asNavFor: '.slick-slider.gallery',
      focusOnSelect: true,
      
      swipeToSlide: true, //* Allow swipe to any position instead of one slide at a time
    });
    
    $('.slick-slider.gallery').slick({
      prevArrow: '<div class="slick-prev"><</div>',
      nextArrow: '<div class="slick-next">></div>',
      asNavFor: '.slick-slider.thumbs',
      responsive: [
      {
        breakpoint: 980,
        settings: {
          dots: false,
          arrows: true
        }
      }
    ]
    });
    $('.slick-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){

});
     $('.slick-next').on('click', function()
    {
      $('#videos-galeria1').get(0).pause();
      $('#videos-galeria2').get(0).pause();
       $('#videos-galeria3').get(0).pause();
       $('#videos-galeria4').get(0).pause();
       $('#videos-galeria5').get(0).pause();
    });
     $('.slick-prev').on('click', function()
    {
      $('#videos-galeria1').get(0).pause();
      $('#videos-galeria2').get(0).pause();
       $('#videos-galeria3').get(0).pause();
       $('#videos-galeria4').get(0).pause();
       $('#videos-galeria5').get(0).pause();
    });

      $('#video-thum1').on('click', function()
    {
      $('#videos-galeria1').get(0).pause();
      $('#videos-galeria2').get(0).pause();
       $('#videos-galeria3').get(0).pause();
       $('#videos-galeria4').get(0).pause();
       $('#videos-galeria5').get(0).pause();
    });

      $('#video-thum2').on('click', function()
    {
      $('#videos-galeria1').get(0).pause();
      $('#videos-galeria2').get(0).pause();
       $('#videos-galeria3').get(0).pause();
       $('#videos-galeria4').get(0).pause();
       $('#videos-galeria5').get(0).pause();
    });

      $('#video-thum3').on('click', function()
    {
      $('#videos-galeria1').get(0).pause();
      $('#videos-galeria2').get(0).pause();
       $('#videos-galeria3').get(0).pause();
       $('#videos-galeria4').get(0).pause();
       $('#videos-galeria5').get(0).pause();
    });

      $('#video-thum4').on('click', function()
    {
      $('#videos-galeria1').get(0).pause();
      $('#videos-galeria2').get(0).pause();
       $('#videos-galeria3').get(0).pause();
       $('#videos-galeria4').get(0).pause();
       $('#videos-galeria5').get(0).pause();
    });

      $('#video-thum5').on('click', function()
    {
      $('#videos-galeria1').get(0).pause();
      $('#videos-galeria2').get(0).pause();
       $('#videos-galeria3').get(0).pause();
       $('#videos-galeria4').get(0).pause();
       $('#videos-galeria5').get(0).pause();
    });




  }
  /* Testimonios */
  if ($('.slick-slider.testimonials').length) {
    $('.slick-slider.testimonials').slick({
      dots: false, 
      arrows: false
    });
    $('.slick-prev.alt').on('click', function()
    {
      $('.slick-slider.testimonials').slick('slickPrev');
    });
    $('.slick-next.alt').on('click', function()
    {
      $('.slick-slider.testimonials').slick('slickNext');
    });
  }

  /* Testimonials categories */
  $('a.testimonials-category-link').attr('href', 'javascript:;')
  $('a.testimonials-category-link').on('click', function(e)
  {
    let category = $(this).data('category');
    $('.testimonial-wrapper').hide();
    $('.testimonial-wrapper.' + category).show();
    $(this).toggleClass('active');
  });

  /* Treatments categories */
  $('a.treatments-category-link').attr('href', 'javascript:;')
  $('a.treatments-category-link').on('click', function(e)
  {
    let category = $(this).data('category');
    $('ul.' + category + '-treatments').slideToggle();
    $(this).toggleClass('active');
  });

  /* The lightcase */
   var iframe_width = Math.round(window_width * .60);
   var iframe_height = Math.round(window_height * .60);
   if (1140 > window_width) {
    iframe_width = Math.round(window_width * .90);
    iframe_height = Math.round(window_height * .60);
   }
   $('a[data-rel^=lightcase]').lightcase({
    showTitle: false,
    showCaption: false,
    forceWidth: true,
    forceHeight: true,
    maxWidth: 1140,
    iframe: {
      width: iframe_width,
      height: iframe_height
    }
  });
   $('a[data-rel^=lightcase-video]').lightcase({
    showTitle: false,
    showCaption: false,
    maxWidth: 1140
  });
  /* The ajax bind */
  if ($('form').length) {
    $('form').attr('novalidate', 'novalidate').on('submit', function(e)
    {
      let recaptcha_action = $('#' + $(this).attr('id') + ' .g-recaptcha').data('action');
      grecaptcha.execute({ action: recaptcha_action });
      e.preventDefault();
      e.stopPropagation();
    });
  }

  if ($('a.temp-nav').length) {
    $('a.temp-nav').on('click', function(e)
    {
      $('a.temp-nav').removeClass('active');
      $('.temp-content').addClass('hidden');
      $(this).addClass('active');
      $('.temp-content.' + $(this).data('cat')).removeClass('hidden');
      e.preventDefault();
    })
  }

  swap_header_logo();
  $(window).resize(function() {
    swap_header_logo();
  });

  /* Temporary slider links */
  $('.temp-link-img').mouseover(function() {
    let over_img = $(this).data('over');
    $(this).attr('src', over_img);
  }).mouseout(function() {
    let default_img = $(this).data('default');
    $(this).attr('src', default_img);
  });

});
